import { render } from "react-dom";
import React from "react";
import SiteSearch from "./site_search";
import PubSub from "pubsub-js";

document.addEventListener("DOMContentLoaded", function() {
  render(
    React.createElement(SiteSearch),
    document.querySelector(".header-items__search")
  );
});
