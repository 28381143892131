import React from "react";
import axios from "axios";

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.addProductToBasket = this.addProductToBasket.bind(this);
    this.successfullyAdded = this.successfullyAdded.bind(this);
    this.state = {
      content: `${this.props.description} - ${this.props.catalogue}`,
      quantity: 1
    };
  }
  successfullyAdded() {
    this.setState({
      added: true
    });
    setTimeout(
      () => {
        this.setState({
          added: false
        });
      },
      1000
    );
  }
  addProductToBasket() {
    axios
      .post(
        "/basket/add_line",
        {
          basket_line: {
            product_id: this.props.product_id,
            quantity: this.state.quantity
          }
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then(() => {
        PubSub.publish("BASKET_UPDATE");
        this.successfullyAdded();
      })
      .catch(error => {
        this.setState({
          error: true
        });
      });
  }
  render() {
    let button_text;
    if (this.state.added === true) {
      button_text = (
        <span>
          <i className="material-icons">&#xE86C;</i>
        </span>
      );
    } else {
      button_text = "Add";
    }
    return (
      <li className="result-item">
        <a href={this.props.url} className="result-item__link">
          {this.props.image !== null ? (<img src={this.props.image.replace("medium", "thumbnail")} />) : ""}
          <span
            dangerouslySetInnerHTML={{
              __html: `${this.props.description} - ${this.props.catalogue}`
            }}
          />
        </a>
        <button
          className={
            `add-to-basket ${this.state.added === true ? "added" : ""}`
          }
          onClick={this.addProductToBasket}
        >
          {button_text}
        </button>
      </li>
    );
  }
}

export default Result;
