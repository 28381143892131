import React from "react";
import { SearchBar } from "./site_search/components/site_search";

class SiteSearch extends React.Component {
  render() {
    return (
      <div className="site-search">
        <SearchBar />
      </div>
    );
  }
}

export { SiteSearch as default };
