import React from "react";
import Result from "./result";
import _ from "lodash";

class SearchResults extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.results.length > 0) {
      return (
        <ul className={`results`}>
          {_.take(this.props.results, 3).map((p, i) => {
            return (
              <Result
                description={p.description}
                product_id={p.step_id}
                catalogue={p.catalogue}
                image={p.image}
                url={p.url}
                key={i}
              />
            );
          })}
          <li className="result-item result-item--view-all">
            <a href={`/product_listings?q=${this.props.search_term}`}>
              View All Search Results
              <i className="material-icons">chevron_right</i>
            </a>
          </li>
        </ul>
      );
    } else if (
      this.props.results.length === 0 && this.props.search_term !== "" && this.props.search_term.length > 3
    ) {
      return (
        <span>
          <ul className="results">
            <li className="no-results">
              Sorry no results have been found, for your search term.
            </li>
          </ul>
        </span>
      );
    } else {
      return <span />;
    }
  }
}

export { SearchResults };
