import React from "react";
import axios from "axios";
import ClickOutside from "react-click-outside/build/index";
import { SearchResults } from "./results";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      results: [],
      suggestions: [],
      focused: ""
    };

    this.search = this.search.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.focused = this.focused.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleClickOutside() {
    if (this.state.focused === "focused") {
      this.closeSearch();
    }
  }
  closeSearch() {
    if (this.state.results.length === 0) {
      this.setState({
        focused: "",
        searchterm: ""
      });
      document.querySelector(".search-bg").classList.toggle("active");
      document
        .querySelector(".header-items__search")
        .classList.toggle("header-items__search--focus");
    } else {
      setTimeout(() => {
        document.querySelector(".search-bg").classList.toggle("active");
        document
          .querySelector(".header-items__search")
          .classList.toggle("header-items__search--focus");
        this.setState({
          searchTerm: "",
          results: [],
          suggestions: [],
          focused: ""
        });
      }, 400);
    }
  }
  focused() {
    document.querySelector(".search-bg").classList.toggle("active");
    document
      .querySelector(".header-items__search")
      .classList.toggle("header-items__search--focus");
    this.setState({
      focused: "focused"
    });
  }
  handleSearchChange(e) {
    this.setState({ searchTerm: e.target.value });
    this.state.searchTerm.length >= 1 ? this.search() : "";
    if (e.keyCode === 13 && this.state.searchTerm.length > 3 && this.state.results.length > 0) {
      window.location = "/product_listings?q=" + this.state.searchTerm;
    }
  }
  search() {
    axios
      .post(
        "/products/search",
        { q: this.state.searchTerm },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then(response => {
        if (response.data.results) {
          this.setState({
            results: response.data.results
          });
        }
      })
      .catch(() => {
        // TODO: Implement Error handling...
        console.log("Error Completing search....");
      });
  }
  render() {
    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <div className="search-bar">
          <input
            className={`form-control ${this.state.focused}`}
            placeholder="Search Engex"
            type="text"
            value={this.state.searchTerm}
            onChange={this.handleSearchChange}
            onKeyUp={this.handleSearchChange}
            onFocus={this.focused}
          />
          <i className="material-icons">&#xE8B6;</i>
        </div>
        <SearchResults
          suggestions={this.state.suggestions}
          results={this.state.results}
          search_term={this.state.searchTerm}
        />
      </ClickOutside>
    );
  }
}

export { SearchBar };
